import { projectFirestore } from "../../../../../firebase/config";
import upload_image_storage from '../../../../../Methods/use_general_storage'
const {delete_image_by_url} = upload_image_storage()

const store_collection_in_db = async(data) => {
    const doc = projectFirestore.collection('Shivuk').doc('Shivuk').collection('Sales_catalog').doc()
    await doc.set({
        ...data,
        uid:doc.id
    })
    return doc.id
}
const edit_collection_in_db = async(data) => {
    await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Sales_catalog').doc(data.uid).set({
        ...data
    },{merge:true})
}

const get_collections_from_db = async() => {
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Sales_catalog').orderBy('created_at','asc').get()
    return docs.docs.map(doc=>doc.data())
}

const delete_collection_from_db = async(collection) => {
    for(const img of collection.images){
        await delete_image_by_url(img.image_url)
    }
    await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Sales_catalog')
    .doc(collection.uid).delete()
}

const get_valid_collections_from_db = async() => {
    const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Sales_catalog').get()
    return docs.docs.filter(doc => {
        if(new Date(doc.data().start_date) < new Date() && new Date(doc.data().end_date) > new Date()){
            return doc
        }
    }).map(doc => doc.data())
}


export{
    store_collection_in_db,
    get_collections_from_db,
    delete_collection_from_db,
    edit_collection_in_db,
    get_valid_collections_from_db
}

