<template>
  <div class="sales-catalog">
    <div class="sales-catalog-wrapper">
        <h1 style="text-align:center;" v-if="images.length==0">{{msg}}</h1>
        <template v-for="img in images" :key="img.image_url">
            <img :src="img.image_url">
        </template>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_valid_collections_from_db} from '../components/Admin/Shivuk_Rosman/sales_catalog/scripts/scripts'
export default {
    setup(){
        
        const msg = ref('קטלוג המבצעים בטעינה...')
        const images = ref([])

        const init = async() => {
            const collections =  await get_valid_collections_from_db()
            collections.forEach(col=>{
                images.value.push(...col.images.filter(img=>img.display).sort((a,b) => a.order-b.order))
            })
            if(images.value.length == 0){
                msg.value = 'כרגע אין קטלוג למבצעים, קטלוג יפורסם בקרוב'
            }
        }

        init()

        return{
            init,
            images,
            msg
        }
    }
}
</script>

<style scoped>
    .sales-catalog{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        color: #333;
        background: #fff;
        z-index: 1000;
    }
    .sales-catalog-wrapper{
        max-width: 1200px;
        margin: 0 auto;
    }
    .sales-catalog img{
        width: 100%;
        margin-bottom: 5px;
    }
</style>